import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MappedinAdComponent } from './components/widgets/mappedin-ad/mappedin-ad.component';
import { MappedinSearchComponent } from './components/widgets/mappedin-search/mappedin-search.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { CommonService } from './services/common.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptors';
import { environment } from '../environments/environment';
import { provideUserIdleConfig } from 'angular-user-idle';
import { AppInitializerService } from './services/app-initializer.service';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
@Injectable()
@NgModule({
  declarations: [AppComponent, MappedinAdComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // AutoCompleteModule,
    // ReactiveFormsModule,
    // FormsModule,
    // ToastModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MappedinSearchComponent,
    DialogModule,
    ButtonModule
  ],
  providers: [
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTagManagerID !== '' ? environment.googleTagManagerID : ''
    },
    MessageService,
    CommonService,
    AppInitializerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializerService) => () => appInitializer.initializeApp(),
      multi: true,
      deps: [AppInitializerService]
    },
    provideUserIdleConfig({ idle: 45, timeout: 16, ping: 0 })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
