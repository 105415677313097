<div class="body-inner">
  <!-- <div class="date-time" *ngIf="mapId && locationFromId">
    <div class="day-date">
      <p class="date">{{ today | date: 'MMMM d, y' }}</p>
      <p class="day">{{ today | date: 'EEEE' }}</p>
    </div>
    <p class="time">{{ today | date: 'h:mm a' }}</p>
  </div> -->
  <!-- Start Mappedin Ad -->
  <div class="mappedin-ad-wpr" *ngIf="mapId && locationFromId">
    <app-mappedin-ad></app-mappedin-ad>
  </div>
  <!-- End Mappedin Ad  -->
  <div class="content-wpr">
    <app-mappedin-search *ngIf="mapId && locationFromId"></app-mappedin-search>
    <router-outlet></router-outlet>
  </div>
  <p-dialog
    header="Reset Floor Map?"
    styleClass="center-dialog"
    [(visible)]="resetMapModalVisible"
    [modal]="true"
    [style]="{ width: '50vw', maxWidth: '600px' }"
    [draggable]="false"
    [resizable]="false"
    [position]="'top'"
    class="infodialog round-dialog reset-dialog">
    <ng-template pTemplate="header">
      <img src="/assets/images/clock.svg" alt="" /><span class="text-xl font-bold timer">{{
        resetFormattedCounter
      }}</span>
    </ng-template>
    <p class="resetModalDescription">Are you still there? Map will reset in following time</p>
    <ng-template pTemplate="footer">
      <p-button (click)="stayButton()" label="STAY" styleClass="theme-button"></p-button>
    </ng-template>
  </p-dialog>
</div>
