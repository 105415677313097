<div class="search-wpr" *ngIf="mapId && locationFromId" #myBounds>
  <div
    class="search-overlay"
    [ngClass]="{ 'show-overlay': isOpenSearch }"
    (click)="openSearch()"
    role="button"
    tabindex="0"
    aria-hidden="true"></div>
  <div class="drag-parent-block">
    <!--
      
      [ngDraggable]="draggable"
      [lockAxis]="'x'"
      [zIndex]="'1000'"
      [bounds]="myBounds"
      [trackPosition]="true"
      [inBounds]="inBounds"
      [outOfBounds]="myOutOfBounds"
      [handle]="myHandle"
      -->
    <div class="right-menu-sec-d">
      <div class="right-menu-sec" [ngClass]="{ open: isOpenMenu }">
        <!-- <div class="menu-btn" (click)="openMenu()" role="button" tabindex="0" aria-hidden="true"></div> -->
        <ul class="right-menu" #myHandle>
          <li>
            <a
              href="javascript:;"
              [hidden]="isOpenSearch"
              (click)="openSearch()"
              id="right-menu--serach"
              class="right-menu--clicks">
              <img src="/assets/images/dmc-search.svg" alt="search icon" />
              <span>Search</span>
            </a>
            <a
              href="javascript:;"
              [hidden]="!isOpenSearch"
              (click)="openSearch()"
              id="right-menu--close"
              class="right-menu--clicks">
              <img src="/assets/images/close.svg" alt="close icon" />
              <span>Close</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              (click)="isOpenSearch = false; onPageNavigationClick('directory')"
              id="right-menu--dir"
              class="right-menu--clicks">
              <img src="/assets/images/dmc-directory.svg" alt="directory icon" />
              <span>Directory</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              (click)="isOpenSearch = false; onPageNavigationClick('all-brands')"
              id="right-menu--brands"
              class="right-menu--clicks">
              <img src="/assets/images/dmc-brands.svg" alt="Brands icon" />
              <span>Brands</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              (click)="isOpenSearch = false; onPageNavigationClick('all-floors')"
              id="right-menu--floors"
              class="right-menu--clicks">
              <img src="/assets/images/dmc-floors.svg" alt="All Floors icon" />
              <span>All Floors</span>
            </a>
          </li>
          <li>
            <a href="javascript:;" (click)="onWashRoomSelect()" id="right-menu--restroom" class="right-menu--clicks">
              <img src="/assets/images/dmc-washroom.svg" alt="washroom icon" />
              <span>Restroom</span>
            </a>
          </li>
          <li>
            <a href="javascript:;" (click)="onResetMap()" id="right-menu--resetmap" class="right-menu--clicks">
              <img src="/assets/images/reset-i.svg" alt="reset icon" />
              <span>Reset Map</span>
            </a>
          </li>
        </ul>
        <div class="search-sec-wpr" [ngClass]="{ open: isOpenSearch }">
          <div class="search-sec" #searchSec>
            <img class="search-icon" src="/assets/images/search-input-icon.svg" alt="" />
            <div class="search-input">
              <!-- <p-autoComplete
                [placeholder]="searchBy === 'showroom' ? 'Search for showroom and brands' : 'Search for restroom'"
                field="name"
                [(ngModel)]="selectedItem"
                [suggestions]="suggestions"
                (onKeyUp)="searchKeyUp()"
                (completeMethod)="search($event)"
                (onClear)="clearPInput()"
                [ngModelOptions]="{ standalone: true }"
                [lazy]="false"
                [showClear]="true"
                [appendTo]="searchSec"
                #pDirectoryAutoComplete
                (onSelect)="onLocationSelect($event)">
                <ng-template pTemplate="loaderTemplate" let-state>
                  <div class="custom-loader">
                    <p-progressSpinner></p-progressSpinner>
                    <span>Loading...</span>
                  </div>
                </ng-template>
                <ng-template let-location pTemplate="item">
                  <div class="directory-sug">
                    <span *ngIf="location?.brandName">{{ location?.brandName }}</span>
                    <div class="directory-number brand-name">
                      <span *ngIf="location?.name">{{ location?.name }} | </span>
                      <span
                        *ngIf="
                          location && location?.space && location?.space?.length && location?.type !== 'amenities'
                        ">
                        {{ location.space.join(', ') }}
                      </span>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete> -->
              <input
                type="text"
                pInputText
                class="search-input"
                [placeholder]="searchBy === 'showroom' ? 'Search for showroom and brands' : 'Search for restroom'"
                [(ngModel)]="searchTerm"
                #pDirectoryInput
                showClear="true"
                appendTo="'searchSec'"
                [ngModelOptions]="{ standalone: true }"
                (input)="debouncedSearch()" />
            </div>
            <div class="other-btns">
              <a
                href="javascript:;"
                class="directory-btn"
                [ngClass]="{ active: searchBy === 'showroom' }"
                (click)="searchBy = 'showroom'; onDirectoryFilter()">
                <svg width="35" height="30" viewBox="0 0 35 30" xmlns="http://www.w3.org/2000/svg">
                  <path
                    class="fill-color"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 29.6522H35V24.7101H0V29.6522ZM0 17.2971H35V12.3551H0V17.2971ZM0 0V4.94203H35V0H0Z"
                    fill="black" />
                </svg>
              </a>
              <a
                href="javascript:;"
                class="washroom-btn"
                [ngClass]="{ active: searchBy === 'washroom' }"
                (click)="searchBy = 'washroom'; onDirectoryFilter()">
                <svg width="51" height="44" viewBox="0 0 51 44" xmlns="http://www.w3.org/2000/svg">
                  <path
                    class="fill-color"
                    d="M13.8757 12.4646C13.7391 12.2037 13.5403 11.9805 13.2968 11.8149C13.0534 11.6493 12.7728 11.5464 12.4799 11.5153C12.3133 11.4517 12.1366 11.4189 11.9582 11.4185H6.61208C6.3788 11.4193 6.14882 11.4736 5.93978 11.5771C5.71038 11.6459 5.49704 11.7598 5.31231 11.9122C5.12758 12.0646 4.97518 12.2524 4.86409 12.4646L0.203679 21.3148C-0.0161092 21.7361 -0.059992 22.2274 0.0816388 22.681C0.22327 23.1346 0.538873 23.5136 0.959349 23.7351C1.38082 23.9541 1.87182 23.9976 2.32524 23.8561C2.77865 23.7145 3.15769 23.3993 3.37964 22.9794L5.08461 19.7523V39.3567C5.08461 39.8339 5.27415 40.2915 5.61155 40.6289C5.94894 40.9663 6.40654 41.1558 6.88369 41.1558C7.36084 41.1558 7.81844 40.9663 8.15584 40.6289C8.49323 40.2915 8.68278 39.8339 8.68278 39.3567V26.8169H9.86872V39.3513C9.86872 39.5876 9.91526 39.8215 10.0057 40.0398C10.0961 40.2581 10.2286 40.4564 10.3957 40.6235C10.5627 40.7905 10.7611 40.9231 10.9793 41.0135C11.1976 41.1039 11.4315 41.1504 11.6678 41.1504C11.9041 41.1504 12.138 41.1039 12.3563 41.0135C12.5746 40.9231 12.7729 40.7905 12.94 40.6235C13.107 40.4564 13.2395 40.2581 13.3299 40.0398C13.4204 39.8215 13.4669 39.5876 13.4669 39.3513V19.4511L15.3332 22.9982C15.555 23.4208 15.9356 23.738 16.3913 23.8799C16.8469 24.0219 17.3403 23.977 17.7629 23.7552C18.1855 23.5334 18.5027 23.1528 18.6446 22.6972C18.7866 22.2415 18.7417 21.7481 18.5199 21.3255L13.8757 12.4646Z" />
                  <path
                    class="fill-color"
                    d="M9.28532 9.99299C11.4255 9.99299 13.1605 8.25803 13.1605 6.11784C13.1605 3.97764 11.4255 2.24268 9.28532 2.24268C7.14513 2.24268 5.41016 3.97764 5.41016 6.11784C5.41016 8.25803 7.14513 9.99299 9.28532 9.99299Z" />
                  <path
                    class="fill-color"
                    d="M46.1471 12.4646C46.0106 12.2037 45.8118 11.9805 45.5683 11.8149C45.3248 11.6493 45.0442 11.5464 44.7514 11.5153C44.5848 11.4517 44.4081 11.4189 44.2297 11.4185H38.8836C38.6503 11.4193 38.4203 11.4736 38.2113 11.5771C37.9819 11.6459 37.7685 11.7598 37.5838 11.9122C37.3991 12.0646 37.2467 12.2524 37.1356 12.4646L32.4752 21.3148C32.2554 21.7361 32.2115 22.2274 32.3531 22.681C32.4948 23.1346 32.8104 23.5136 33.2308 23.7351C33.6523 23.9541 34.1433 23.9976 34.5967 23.8561C35.0501 23.7145 35.4292 23.3993 35.6511 22.9794L37.3561 19.7523V20.0374L33.3223 30.7943H37.3561V39.3621C37.3561 39.8392 37.5456 40.2968 37.883 40.6342C38.2204 40.9716 38.678 41.1612 39.1552 41.1612C39.6323 41.1612 40.0899 40.9716 40.4273 40.6342C40.7647 40.2968 40.9543 39.8392 40.9543 39.3621V30.7835H42.1402V39.3513C42.1402 39.8285 42.3297 40.2861 42.6671 40.6235C43.0045 40.9609 43.4621 41.1504 43.9393 41.1504C44.4164 41.1504 44.874 40.9609 45.2114 40.6235C45.5488 40.2861 45.7384 39.8285 45.7384 39.3513V30.7835H49.5033L45.7384 20.0266V19.435L47.6047 22.9821C47.8265 23.4047 48.2071 23.7218 48.6628 23.8638C49.1184 24.0058 49.6118 23.9609 50.0344 23.7391C50.457 23.5173 50.7741 23.1367 50.9161 22.681C51.0581 22.2254 51.0132 21.732 50.7914 21.3094L46.1471 12.4646Z" />
                  <path
                    class="fill-color"
                    d="M41.5597 9.99299C43.6999 9.99299 45.4349 8.25803 45.4349 6.11784C45.4349 3.97764 43.6999 2.24268 41.5597 2.24268C39.4195 2.24268 37.6846 3.97764 37.6846 6.11784C37.6846 8.25803 39.4195 9.99299 41.5597 9.99299Z" />
                  <path class="fill-color" d="M26.2253 0H25.6875V43.3932H26.2253V0Z" />
                </svg>
              </a>
            </div>
          </div>
          <div class="global-search-result">
            <div class="two-v-scroller">
              <div>
                <p-virtualScroller
                  #vs
                  [value]="suggestionLocations"
                  styleClass="global-search-list"
                  scrollHeight="300px"
                  *ngIf="searchTerm && suggestionLocations?.length"
                  [itemSize]="60">
                  <ng-template pTemplate="header">
                    <div class="search-list-label">Showroom</div>
                  </ng-template>
                  <ng-template pTemplate="item" let-item>
                    <a href="javascript:;" (click)="onLocationSelect({ id: item?.id, polygonID: item?.polygonID })">
                      <span *ngIf="item?.name">{{ item?.name }}</span>
                      <div class="directory-number">
                        <span *ngIf="item.space">
                          {{ item.space }}
                        </span>
                        <!-- <span *ngIf="item && item?.space && item?.space?.length">
                          {{ item.space.join(', ') }}
                        </span> -->
                      </div>
                    </a>
                  </ng-template>
                </p-virtualScroller>
              </div>
              <div>
                <p-virtualScroller
                  #vs
                  [value]="suggestionBrands"
                  styleClass="global-search-list"
                  *ngIf="searchTerm && suggestionBrands?.length"
                  [itemSize]="60">
                  <ng-template pTemplate="header">
                    <div class="search-list-label">Brands</div>
                  </ng-template>
                  <ng-template pTemplate="item" let-item>
                    <a href="javascript:;" (click)="onLocationSelect({ id: item?.id, polygonID: item?.polygonID })">
                      <span *ngIf="item?.brandName">{{ item?.brandName }}</span>
                      <div class="directory-number">
                        <span *ngIf="item?.name">{{ item?.name }} | </span>
                        <span *ngIf="item.space">
                          {{ item.space }}
                        </span>
                        <!-- <span *ngIf="item && item?.space && item?.space?.length">
                          {{ item.space.join(', ') }}
                        </span> -->
                      </div>
                    </a>
                  </ng-template>
                </p-virtualScroller>
              </div>
            </div>
            <div class="simple-keyboard" id="keyboard2" *ngIf="isOpenSearch"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-toast key="toast-searchbar"></p-toast>
</div>
